<template>
  <div class="askDiagnosis">
    <h3>屈光发育历：</h3>
    <div class="qgfy">
      <div class="qgfx-box">
        <p style="padding: 0;">近视年龄</p>
        <el-input class="sr" placeholder="请输入内容"></el-input>
        <span>岁</span>
      </div>
      <div class="qgfx-box">
        <p>每年增长幅度</p>
        <el-input class="sr" placeholder="请输入内容"></el-input>
      </div>
      <div class="qgfx-box">
        <p>矫正治疗方案</p>
        <el-select class="sr" v-model="value" filterable allow-create  placeholder="请选择">
          <el-option
              v-for="item in zxdw"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-input class="sr" placeholder="请输入内容"></el-input>
        <span>年</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "askDiagnosis",
  data() {
    return {
      value: '',
      zxdw: [
        {
          value: '框架',
          label: '框架'
        },
        {
          value: 'SCL',
          label: 'SCL'
        },
        {
          value: 'RGP',
          label: 'RGP'
        },
        {
          value: 'OK镜',
          label: 'OK镜'
        },
        {
          value: '其他',
          label: '其他'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  .askDiagnosis {
    padding: 0 3vw;
    height: 87vh;
    overflow-y: auto;
  }
  h3 {
    text-align: left;
    padding-top: .3rem;
    padding-bottom: .1rem;
  }
  .qgfy {
    display: flex;
    justify-content: start;
    align-items: center;
    .qgfx-box {
      display: flex;
      align-items: center;
      justify-content: start;
      .sr {
        width: 1.5rem;
        margin: 0 .1rem;
      }
      p {
        padding-left: .8rem;
      }
    }
  }
</style>